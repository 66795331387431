import React from 'react'
import { observer } from 'mobx-react'
import './EventDetail.scss'
import { items as members }from 'Stores/User'
import stores, { getStore } from 'Stores'
import EventDetail from '.'

const debug = require('debug')('treks:container:event:detail')

class EventDetailContainer extends React.Component {

  static defaultProps = {
    get actionPlanner() {
      return getStore(stores.ActionPlanner)
    }
  }

  componentDidMount() {
    const { focusedEvent } = this.props
    if (focusedEvent) {
      focusedEvent.actionPlanner.memberList.setItems(members)
    }
  }

  componentDidUpdate(prevProps) {
    const { focusedEvent } = this.props
    if (focusedEvent && prevProps.focusedEvent !== focusedEvent) {
      focusedEvent.actionPlanner.memberList.setItems(members)
    }
  }

  render() {
    const { actionPlanner } = this.props
    const { focusedEvent } = actionPlanner
    debug('render', { focusedEvent })
    if (!focusedEvent) return null
    return (
      <EventDetail
        actionPlanner={actionPlanner}
        event={focusedEvent}
      />
    )
  }
}

export default observer(EventDetailContainer)