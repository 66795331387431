import { action, observable } from "mobx"
import Model from "Stores/Model"
import { getStore } from "Stores/Stores"
import ActionPlannerUtils, { dateAddDays, toStartOfDayDate } from "../ActionPlannerUtils"
import { MultiDayPlannerConfig } from "../config/MultiDayPlannerConfig"
import { PlannerFlow } from "../PlannerFlow/PlannerFlow"
import { getTodayStartDate } from "./utils"

export class DayPlanner extends Model {

  plannerFlow = PlannerFlow.fromProps({
    startDate: dateAddDays(getTodayStartDate(), -1),
    endDate: dateAddDays(getTodayStartDate(), 2)
  }) as PlannerFlow

  utils = new ActionPlannerUtils(MultiDayPlannerConfig.default)

  @observable isUiCollapsed: boolean = false

  @action toggleUiExpandCollapse() {
    this.utils.setOpts(this.isUiCollapsed ? MultiDayPlannerConfig.default: MultiDayPlannerConfig.collapsed)
    this.isUiCollapsed = !this.isUiCollapsed
  }

}

export const getDayPlanner = (): DayPlanner => getStore(DayPlanner)

global.dayPlanner = getStore(DayPlanner)