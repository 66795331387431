import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'react-bootstrap/Modal'
import Button from '../theme/Button'
import Icon from '../theme/Icon'

class ModalContainer extends React.Component {

  render() {
    const { show, title, icon, component, onHide, style } = this.props
    return (
      <Modal 
          show={show} 
          onHide={onHide}
          size="lg"
          centered
          style={style}
        >
        <Modal.Header>
            <Modal.Title>
              <Icon set="fa" icon={icon} size="xs" color={'#808284'} className="modal-title-icon" />
              {title}
            </Modal.Title>
            <Button variant="outline" onClick={onHide} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {component}
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(ModalContainer)