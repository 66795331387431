import React from "react"
import './Collapsable.scss'

export default class Collapsable extends React.Component {

  collapsableRef = null

  static defaultProps = {
    animationDuration: 500,
    isCollapsed: false
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.isCollapsed !== prevProps.isCollapsed) {
      this.animateUi()
    }
    return null
  }

  componentDidUpdate() {}

  getElementRefDimensions(ref) {
    return ref.getBoundingClientRect ? ref.getBoundingClientRect() : {
      width: ref.offsetWidth,
      height: ref.offsetHeight
    }
  }

  animateUi = () => {
    if (this.collapsableRef) {
      const { height } = this.getElementRefDimensions(this.collapsableRef)
      if (this.props.isCollapsed) {
        this.collapsableRef.style.height = height + 'px'
        setTimeout(() => {
          if (this.collapsableRef) this.collapsableRef.style.height = '0'
        })
      } else {
        this.collapsableRef.style.height = this.prevCollapsableRefHeight + 'px'
        setTimeout(() => {
          if (this.collapsableRef) this.collapsableRef.style.height = ''
        }, this.props.animationDuration)
      }
      this.prevCollapsableRefHeight = height
    }
  }

  render() {
    const collapseClass = this.props.isCollapsed ? ' collapsed' : ''
    return (
      <div
        ref={ref => this.collapsableRef = ref}
        className={'collapsable' + collapseClass}
        >
          {
            this.props.children
          }
      </div>
    )
  }
}