import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import ListViewTaskItem from './ListViewTaskItem'
import ListTitle from 'Components/ui/SubTasks/ListTitle'
import Sortable from 'Modules/ReactSortable/ReactSortable';
import { action, computed, makeObservable, observable } from 'mobx'
import Icon from 'theme/Icon';
import { AddItemBtn } from 'Components/ui/AddItemBtn'
import TaskItem from 'Stores/Task/TaskItem'
import { ListFilter } from '../Shared/Model/ListFilter'
import { Collapsable, CollapsableIcon } from 'theme/Collapsable'
import { models } from 'Stores'
import { RemoveBtn } from 'theme/RemoveBtn/RemoveBtn'
import { Position, getAppNotifications } from 'AppNotifications'
import { ListViewUiModel } from '../Shared/ListViewContainer/models/ListViewUiModel'
import { IconPlusCircle } from 'theme/Icons/IconPlusCircle'

const debug = require('debug')('treks:list-view:task-list')

class ListViewTaskList extends React.Component {

  static propTypes = {
    list: PropTypes.objectOf(() => models.TaskList).isRequired
  }

  static defaultProps = {
    uiStore: getStore(ListViewUiModel),
    itemHeight: null,
    showHeader: true,
    showFooter: true
  }

  componentDidMount() {
    const { list } = this.props
    const isProjectOrCategoryList = list.project?.id || list.category?.id
    const el = this.getTaskListEl()
    const bottomVisible = this.isVisibleElBottomInViewport(el)
    if (bottomVisible && isProjectOrCategoryList && list.hasNextFetchablePage()) {
      setTimeout(() => list.fetchNextPage(), this.isCollapsed ? 2000 : 0)
    }
    this.showNextPageOnScroll()
  }

  componentWillUnmount() {
    this.removeScrollListeners()
  }

  getPaneEl() {
    const elId = this.getDataId()
    return document.querySelector(`.main-pane-component:has([data-id="${elId}"])`)
  }

  getTaskListEl() {
    const elId = this.getDataId()
    return document.querySelector(`[data-id="${elId}"]`)
  }

  showNextPageOnScroll() {
    const paneEl = this.getPaneEl()
    paneEl.addEventListener('scroll', this.onScroll)
    window.addEventListener('scroll', this.onScroll)
  }

  removeScrollListeners() {
    const paneEl = this.getPaneEl()
    paneEl.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('scroll', this.onScroll)
  }

  scrollTimer = null

  onScroll = () => {
    const { list } = this.props
    if (this.scrollTimer) clearTimeout(this.scrollTimer)
    this.scrollTimer = setTimeout(() => {
      if (this.isCollapsed) return
      const el = this.getTaskListEl()
      const bottomVisible = this.isVisibleElBottomInViewport(el)
      if (bottomVisible) {
        const hasNextFetch = list.hasNextFetchablePage()
        const hasNextPage = list.hasNextVisiblePage()
        if (hasNextFetch) {
          list.fetchNextPage()
        }
        if (hasNextPage) {
          debug('show', el, list.hasNextVisiblePage(), list)
          list.showNextPage()
        }
        if (!hasNextPage && !hasNextFetch) {
          const paneEl = this.getPaneEl()
          paneEl.removeEventListener('scroll', this.onScroll)
        }
      }
    }, 50)
  }

  @observable isCollapsed = false

  onPressCollapsable = () => {
    this.isCollapsed = !this.isCollapsed
  }

  listFilter = new ListFilter()

  @computed get filteredItems() {
    const { items, filter } = this.props
    return this.listFilter.getFilteredItems(items, filter)
  }

  constructor() {
    super()
    makeObservable(this)
  }

  findSubTaskByUid(uid) {
    debug('this.props.list.ModelType', this.props.list.ModelType.name)
    return this.props.list.ModelType.findModel({ uid })
  }

  findSubTasksByUids(uids) {
    return uids.map(uid => this.findSubTaskByUid(uid))
      .filter(subtask => subtask)
  }

  @action onDragAdd = (event) => {
    const { list, items, noCategory, noProject } = this.props
    const { item, oldIndex, newIndex } = event
    debug('onDraggAdd', { event, list, newIndex, oldIndex })
    const taskUid = item.getAttribute('data-id')
    const task = this.findSubTaskByUid(taskUid)
    if (!task) {
      console.warn('Task not found with uid', taskUid)
      return // should not happen
    }
    const hasItem = items.find(item => task.uid === item.uid)
    if (!hasItem) {
      const prevOrder = list.getOrder()

      // @important remove this when relationship fixed. 
      // List.addItem() should remove from other category
      // @note removing cat/project will not set order
      if (noCategory) {
        task.category.taskList.removeItem(task)
        if (noProject) {
          task.project.taskList.removeItem(task)
        }
      } else {
        list.addItem(task, newIndex)
      }
      const currOrder = list.getOrder()
      debug('addItem', task.title, { task, newIndex }, { prevOrder, currOrder })
      list.saveOrder()
      task.save()
    }
  }

  @action onDragRemove = () => {
    const { list } = this.props
    this.nextOrder = null // prevent re-order when drag out
    if (list.visibleItems.length === 0 && list.isFlatList) {
      list.trash()
      list.list?.removeItem(list)
    }
  }

  @action onDragStart = (args) => {
    debug('drag start', args)
    this.isDragging = true
    this.nextOrder = null
  }

  @action onDragEnd = ({ newIndex, item }) => {
    const { list, uiStore } = this.props
    const { isCtrlKeyPressed } = uiStore
    debug('drag end', { newIndex, item, isCtrlKeyPressed })
    if (this.isDragging && this.nextOrder) {
      list.setOrder(this.nextOrder)
      list.saveOrder()
      this.nextOrder = null
    }
    this.isDragging = false
    // add subtask after onDragAdd and setOrder(this.nextOrder) 
    if (isCtrlKeyPressed) {
      const taskUid = item.getAttribute('data-id')
      const task = models.TaskItem.findByUid(taskUid)

      debug('control pressed', { uiStore, taskUid, task })
      if (task && newIndex > 0) {
        const parentTask = list.getItemByIndex(newIndex - 1)
        debug('parent', parentTask)
        if (parentTask) {
          try {
            models.TaskItem.canBeSubTask(parentTask, task)
            parentTask.subTasksList.addItem(task)
            task.project = parentTask.project
            task.category = parentTask.category
            task.save()
          } catch(e) {
            getAppNotifications().error(e.message)
            console.warn(e)
          }
        }
      }
    }
  }

  @action onSort = order => {
    debug('onSort', order)
    if (!this.isDragging) {
    debug('onSort ignore', order)
      return // only id reordering
    }
    this.nextOrder = order
  }

  onPut = (_, __, el) => {
    const { list, items } = this.props
    const uid = el.getAttribute('data-id')
    const isParentTask = list.task && list.task.uid === uid
    const hasTask = items.find(item => uid === item.uid)
    const model = TaskItem.findByUid(uid)
    const isTask = model && model instanceof TaskItem
    debug('onPut', { _, __, el, uid, model, isParentTask, hasTask, list, isTask })
    return !(isParentTask || hasTask) && isTask
  }

  onAddTask = () => {
    const task = this.props.list.addItem({ duration: 15 })
    task.setFocusOnTitle()
    debug('add task', task, this.props.list)
  }

  isVisibleElBottomInViewport(el, margin = 600) {
    const { bottom } = el.getBoundingClientRect();
    const { innerHeight } = window;
    return bottom >= 0 && bottom <= innerHeight + margin;
  }

  getDataId() {
    const { parent, category } = this.props
    return parent.title.replace(' ', '_') + '-' 
      + category.title.replace(' ', '_') + '-' 
      + parent.uid + '-'
      + category.uid
  }

  deleteCategoryAndTasks = async () => {
    const { category, items } = this.props
    const tasks = items
    debug('delete category and tasks', { category, tasks })
    category.setProp('deleted', true)
    const deleteTasks = tasks.map(task => {
      task.trash()
      return task.save()
    })
    await Promise.all(deleteTasks)
    await category.save()
    getAppNotifications().error({
      position: Position.TOP,
      message: `Deleted category "${category.title}" and all tasks in category`
    })
  }

  deleteCategoryOnly = async () => {
    const { category, items } = this.props
    const tasks = items
    debug('Delete category only', { category, tasks })
    category.setProp('deleted', true)
    await category.save()
    const updateTasks = tasks.map(task => {
      category.taskList.removeItem(task)
      return task.save()
    })
    await Promise.all(updateTasks)
    getAppNotifications().error({
      position: Position.TOP,
      message: `Deleted category "${category.title}"`
    })
  }

  onPressRemove = () => {
    const note = getAppNotifications()
    note.warn({
      position: Position.TOP,
      message: 'Delete Category and all tasks in category?',
      action: {
        text: 'Delete Category and Tasks',
        onClick: () => this.deleteCategoryAndTasks()
      },
      undo: {
        text: 'Delete Category Only',
        onClick: () => this.deleteCategoryOnly()
      }
    })
  }

  renderItem({ list, item, parent, fields, index }) {
    return (
      <React.Fragment key={item.uid}>
        <div 
          className='task-container'
          data-id={item.uid}
        >
          <ListViewTaskItem 
            list={list}
            item={item}
            parent={parent}
            fields={fields}
          />
        </div>
        {
          item.subTasksList.items.map(subtask => {
              return (
                <div
                  className='subtask-container'
                  data-id={subtask.uid} 
                  key={subtask.uid}
                >
                  <ListViewTaskItem
                    item={subtask}
                    list={item.subTasksList}
                    parent={item}
                    fields={fields}
                  />
                </div>
              )
            })
        }
    </React.Fragment>
    )
  }

  sortItemsByTitle(items, order) {
    if (order === 'asc') {
        return items.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -1 : 0));
    } else if (order === 'desc') {
        return items.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? 1 : ((b.title.toLowerCase() < a.title.toLowerCase()) ? -1 : 0));
    } else {
        return items;
    }
}

  renderSortable({ isFetching, items, list, parent, fields, order }) {
    if (order) {
      items = this.sortItemsByTitle(items, order)
    }
    return (
      <Sortable
        className={'task-list-body' + (isFetching ? ' busy' : '')}
        options={{
          handle: '.icon-drag',
          animation: 100,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          group: {
            name: 'subtasks',
            put: () => this.onPut,
          },
          onStart: this.onDragStart,
          onEnd: this.onDragEnd,
          onAdd: this.onDragAdd,
          onRemove: this.onDragRemove
        }}
        onChange={this.onSort}
      >
        {
          items.map((item, index) => {
            return this.renderItem({ list, item, parent, fields, index })
          })
        }
      </Sortable>
    )
  }

  onClickList = (event) => {
    const { list, items } = this.props
    Object.assign(this.props.uiStore.selectedTaskListState, {
      list,
      items
    })
  }

  render() {
    const { category, list, items, parent, filter, fields, order, showHeader, showFooter } = this.props
    const { filteredItems } = this
    const nonSubTaskFilteredItems = filteredItems.filter(item => !item.parentTask.id)
    const containerClass = 'task-list-container'
    const flatClass = list.isFlatList ? 'flat-list' : 'hierachical-list'
    const trashedClass = list.trashed ? 'trashed-list' : ''
    const emptyClass = nonSubTaskFilteredItems.length === 0 ? 'empty-list' : ''
    const className = [containerClass, flatClass, trashedClass, emptyClass]
      .map(className => className)
      .join(' ')
    const isFetching = list.fetchState.isBusy || list.hasNextVisiblePage()
    debug('render', category.title, { category, parent, list, items, filteredItems, nonSubTaskFilteredItems, filter, order })
    return (
      <div
        className={className}
        data-id={this.getDataId()}
        onClick={this.onClickList}
      >
        <div className={'task-list' + (!showHeader ? ' no-header' : '')}>
          {
            showHeader && (
              <div className="task-list-header">
                <Icon name={'drag'} color={'#97aaae'} size={10} />
                <ListTitle item={category} list={list} parent={parent} />
                <CollapsableIcon
                  onPress={this.onPressCollapsable}
                  isCollapsed={this.isCollapsed}
                />
                <RemoveBtn onPress={this.onPressRemove} />
              </div>
            )
          }
          
          <Collapsable isCollapsed={this.isCollapsed}>
            {
              this.renderSortable({
                isFetching,
                items: nonSubTaskFilteredItems,
                list,
                parent,
                fields,
                order
              })
            }
            {
              showFooter && (
                <footer className="add-item-btns">
                  <AddItemBtn
                    label={(
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconPlusCircle size={18} />
                        <span style={{ marginLeft: 5 }}>{' Add Task'}</span>
                      </div> 
                    )}
                    onClick={this.onAddTask}
                    className={'btn-add-task'}
                    tooltipTitle={'Enter'}
                  />
                </footer>
              )
            }
          </Collapsable>
        </div>
      </div>
     )
  }
}

export default observer(ListViewTaskList)