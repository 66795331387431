import { observable, action, override, computed } from 'mobx'
import TimeSpanListDaySettings from './Settings/TimeSpanListDaySettings'
import { emptyTimeSpan } from './items'
import OrderedList from 'Stores/Lists/OrderedList'
import { TimeSpanBucketList, TimeSpanList, TimeSpanItem } from 'Stores/ActionPlanner/TimeSpan'
import ActionPlanner from '..'

const debug = require('debug')('treks:store:timespan:days')

export default class DayList extends OrderedList {

  @observable actionPlanner: ActionPlanner = null

  get ModelType() {
    return TimeSpanListDaySettings
  }

  @override get items(): TimeSpanList[] {
    return super.items
  }

  @computed get today(): TimeSpanList {
    return this.items.find(day => {
        return day.dayDate.getTime() === this.startOfTodayDate.getTime()
      })
  }

  @computed get startDay(): TimeSpanList {
    return this.items.length > 0 && this.items[0]
  }

  @computed get endDay(): TimeSpanList {
    return this.items.length > 0 && this.items[this.items.length - 1]
  }

  @computed get focusedDay() {
    return this.actionPlanner?.focusedDay
  }

  @computed get startOfTodayDate(): Date {
    return this.actionPlanner?.startOfTodayDate
  }

  /**
   * @property {object[]} List of Timespan items to use
   */
  @computed get bucket(): TimeSpanBucketList {
    return this.getAttribute('bucket', () => TimeSpanBucketList.fromProps({ dayList: this }))
  }

  createItemProps(props) {
    return super.createItemProps({ dayList: this, ...props })
  }

  @observable focusedItem: TimeSpanItem

  @observable emptyItem = emptyTimeSpan


  @action setBucketItems(items:TimeSpanItem[], list: DayList = this) {
    this.bucket.setItems(items, list)
  }

  getItemByDayId(dayId: string) {
    return dayId && this.items.find(item => {
      return item.dayId.toString() === dayId.toString()
    })
  }

  @action setFocusedItem(item: TimeSpanItem, day: TimeSpanList) {
    this.focusedItem = item
    this.focusedItem.day = day
  }

  @action async fetchLocal() {
    return this.localState.get('day/timespan/list')
      .then(data => this.fromJSON(data))
  }

  async saveLocal() {
    this.localState.set('day/timespan/list', this.toJSON())
  }

  @override async fetch() {
    this.fetchLocal()
    return this.fetchState.get('account/day/timespan/list')
      .then(resp => {
        this.updateItems(resp.data.list)
        return resp
      })
  }

  async save() {
    debug('save', this.fetchState.state)
    const list = this.items.filter(item => item.duration)
    return this.saveState.postJson('account/day/timespan/list/save', { list })
  }

}