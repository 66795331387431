import { observable, computed, action } from 'mobx'
import Item from '../Lists/Item'
import moment from 'moment'
import CommentList from './CommentList'
import CommentFollowerList from './CommentFollowerList';
import { importUser } from 'Stores/User'
import { ProjectItem } from 'Stores/Project';
import TaskItem from 'Stores/Task';
import { UserI } from 'Stores/User/Type/User';
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators';

export default class CommentItem extends Item {

  @observable title: string = ''

  @observable text: string = ''

  @observable deleted: boolean = false

  @observable userId: string = ''

  @hasOne(() => CommentItem)
	replyTo:CommentItem

	@hasOne(() => ProjectItem)
  project:ProjectItem

  // @note mobx deletes this.task before making observable/computed and errors
  // @todo can we allow the delete when using relationship? 
  // @hasOne(() => TaskItem)
	task:TaskItem

  @computed get user(): UserI {
    return importUser().getModel({ id: this.userId })
  }
  set user(user: UserI) {
    this.userId = user.id
  }

  @observable likeCount:number = 0

  @observable liked:boolean = false

  @observable commentDate:Date = new Date()

  @hasMany(() => CommentList, (list: CommentList) => list.comment)
  replies: CommentList

  @action addReply(comment:any) {
    return this.replies.addItem(comment)
  }

  @hasOne(() => CommentFollowerList)
  followers: CommentFollowerList

  @observable sendNotifications:boolean = false

  @computed get since() {
    return moment(this.commentDate).from(this.currentDate)
  }

  async save() {
    return this.saveState.postJson('comment/save', this.toJSON())
  }

  async saveLike() {
    return this.saveState.postJson('comment/like/save', {
      commentId: this.id,
      published: true
    })
  }

}