import React from 'react'
import loadable from '@loadable/component'
import ListViewContextMenu from 'Components/ListView/Shared/ContextMenu/ListViewContextMenu'

// lazy load components
const ActionPlannerRoutes = loadable(() => import('Routes/Planner/PlannerRoutes'))
const TaskDetailComponent = loadable(() => import('Containers/TaskDetail'))
const CalendarComponent = loadable(() => import('Containers/Calendar'))
const AccountComponent = loadable(() => import('Components/Account'))
const ProjectDetailComponent = loadable(() => import('Containers/Project'))
const HabitDetailComponent = loadable(() => import('Containers/Habit'))

const GoalDetailComponent = loadable(() => import('Containers/Goal'))
const EventDetailComponent = loadable(() => import('Components/EventDetail/EventDetailContainer'))
const TaskListComponent = loadable(() => import('Components/ListView/ListViewTasks'))
const HabitListComponent = loadable(() => import('Components/ListView/Habits'))
const ProjectListComponent = loadable(() => import('Components/ListView/Projects'))
const GoalListComponent = loadable(() => import('Components/ListView/Goals'))
const InboxComponent =  loadable(() => import('Components/Inbox/InboxGroup'))
const DashboardComponent =  loadable(() => import('Components/Dashboard/Dashboard'))

// lazy footers
const DayPlannerFooter = loadable(() => import('Components/ActionPlanner/DayPlanner/DayPlannerFooter'))
const WeekPlannerFooter = loadable(() => import('Components/ActionPlanner/WeekPlannerFooter'))
const GoalDetailFooter = loadable(() => import('Components/Goal/GoalDetail/GoalDetailFooter'))
const TaskDetailFooter = loadable(() => import('Components/TaskDetail/TaskDetailFooter'))
const EventDetailFooter = loadable(() => import('Components/EventDetail/EventDetailFooter'))

export const mainPaneComponents = {
  'day-planner': {
    component: props => <ActionPlannerRoutes {...props} />,
    footer: props => <DayPlannerFooter {...props} />
  },
  'week-planner': {
    component: props => <ActionPlannerRoutes {...props} view={'week'} />,
    footer: props => <WeekPlannerFooter {...props} />
  },
  dashboard: {
    component: props => <DashboardComponent {...props} />
  },
  inbox: {
    component: props => <InboxComponent {...props} />
  },
  'task-detail': {
    component: props => <TaskDetailComponent {...props} />,
    footer: props => <TaskDetailFooter {...props} />
  },
  'calendar-week': {
    component: props => <CalendarComponent {...props} view={'week'} /> 
  },
  account: {
    component: props => <AccountComponent {...props} />
  },
  project: {
    component: props => <ProjectDetailComponent {...props} />
  },
  goal: {
    component: props => <GoalDetailComponent {...props} />,
    footer: props => <GoalDetailFooter {...props} />
  },
  'event-detail': {
    component: props => <EventDetailComponent {...props} />,
    footer: props => <EventDetailFooter {...props} />
  },
  'tasks': {
    component: props => <TaskListComponent {...props} />,
    footer: props => <ListViewContextMenu {...props} menuType={'footer'} />
  },
  'habits': {
    component: props => <HabitListComponent {...props} />
  },
	habit: {
    component: props => <HabitDetailComponent {...props} />
  },
  'projects': {
    component: props => <ProjectListComponent {...props} />
  },
  goals: {
    component: props => <GoalListComponent {...props} /> 
  }
}
