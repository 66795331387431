import { observer } from 'mobx-react'
import React from 'react'
import TaskDetailRow from '../TaskDetailRow'

const debug = require('debug')('treks:task:addcomment')

class TaskAddComment extends React.Component {

  componentDidMount() {
    setTimeout(() => this.handleScroll(), 50)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentDidUnMount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const body = document.documentElement
    const offset =  (body.offsetHeight - body.clientHeight) - body.scrollTop
    requestAnimationFrame(() => {
      // el may not exist if component unmounted during scroll
      const el = document.querySelector('.task-comment-add')
      if (el) el.style.marginBottom = String(offset) + 'px'
    })
  }

  addComment(comment) {
    if (!comment) return
    debug('adding comment', comment)
    const { store } = this.props
    const props = {
      user: this.props.account,
      comment,
      commentDate: new Date()
    }
    comment = store.addItem(props)
    comment.save()
  }

  onKeyUp = event => {
    if (event.key === 'Enter') {
      const comment = event.target.value
      event.target.value = ''
      this.addComment(comment)
    }
  }

  render() {
    return (
      <div className="container task-comment-add">
        <TaskDetailRow icon="comment" className="row-flex">
          <input 
            className="comment-input" 
            type="text" 
            onKeyUp={this.onKeyUp} 
          />
        </TaskDetailRow>
      </div>
    )
  }
}

export default observer(TaskAddComment)