import React from 'react'
import Icon from '../../../theme/Icon'
import TaskCheckBox from '../../TaskDetail/TaskCheckbox'
import TaskTitle from '../../TaskDetail/TaskTitle'
import TaskDuration from '../../TaskDetail/TaskDuration'
import Tooltip from 'theme/Tooltip'
import moment from 'moment'
import TaskRemoveFromPlannerIcon from '../../TaskDetail/TaskRemoveFromPlannerIcon';
import { observer } from 'mobx-react';
import ActionPlannerTaskBody from './ActionPlannerTaskBody'
import TaskProject from './TaskProject'
import { TaskSticky } from './TaskSticky'
import { TaskEarmarkedDayLabel } from './TaskEarmarkedLabel'

const debug = require('debug')('treks:planner:task')

const showTaskEndTimeTooltip = false // @todo move to config

class ActionPlannerTask extends React.Component {

  static defaultProps = {
    style: {}
  }

  onInputRef = ref => {
    this.inputRef = ref
  }

  focusOnInput() {
    if (this.inputRef) {
      window.setTimeout(() => this.inputRef.focus())
    }
  }

  onInputChange = event => {
    event.stopPropagation()
    const title = event.target.value
    this.saveItemTitle(title)
  }

  onFocusInput = () => {
    this._focusOnItem()
  }

  saveItemTitle = title => {
    const { item } = this.props
    item.setTitle(title)
    item.save()
  }

  onFocusDuration = () => {
    this._focusOnItem()
  }

  onFocusCheckbox = () => {
    this._focusOnItem()
  }

  _focusOnItem() {
    const { uid } = this.props.item
    const { actionPlannerStore } = this.props
    // use uid as id may not be set yet
    const item = actionPlannerStore.getItemByUid(uid)
    debug('focusing on', item)
    actionPlannerStore.setFocusedItem(item)
  }

  onRemoveFromPlanner = () => {
    debug('removing from planner', this.props.item)
    this.props.item.toggleOnPlanner()
    this.props.item.save()
  }

  componentDidMount() {
    const { actionPlannerStore, item } = this.props
    this.editDisposer = item.reaction(() => item.editing, () => {
      this.focusOnInput()
      actionPlannerStore.setEditingItem(null)
    })
  }

  componentWillUnmount() {
    this.editDisposer && this.editDisposer()
  }

  render() {
    const { item, actionPlannerStore, plannerFlow, taskPiece, style } = this.props
    const { done, isResizing } = item
    const tooltipStyle = {
      bottom: 0,
      left: 'calc( 50% - 100px )',
      width: 200
    }
    debug('render', { item, actionPlannerStore })
    return (
      <div className="action-planner-item" style={style}>
        <header className="header">
          <Icon className="row-icon" name={'drag'} color={'#97aaae'} size={10} />
          <TaskCheckBox item={item} size={14} onFocus={this.onFocusCheckbox} />
          <TaskTitle
            item={item}
            list={actionPlannerStore}
            onChange={this.onInputChange}
            onFocus={this.onFocusInput}
            isPlannerTask={true}
            disabled={done}
          />
          <TaskProject project={item.project} />
          {
            item.isSticky ? <TaskSticky color="#97aaae" /> : null
          }
          <TaskDuration
            item={item}
            list={actionPlannerStore}
            onFocus={this.onFocusDuration}
            disabled={done}
          />
          <TaskRemoveFromPlannerIcon
            item={item}
            onClick={this.onRemoveFromPlanner}
          />
          {
            item.isEarmarked ? (
              <TaskEarmarkedDayLabel
                plannerFlow={plannerFlow}
                task={item}
              />
            ) : null
          }
        </header>
        <ActionPlannerTaskBody
          item={item}
          taskPiece={taskPiece}
        />
        {
          showTaskEndTimeTooltip && isResizing && (
            <Tooltip placement="top" tooltipStyle={tooltipStyle}>
              {moment(item.startDate).format('h:mm a')}
            </Tooltip>
          )
        }
      </div>
    )
  }
}

export default observer(ActionPlannerTask)
