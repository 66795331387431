import React from 'react'
import Icon from 'theme/Icon'
import './PlannerTaskContextMenu.scss'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow'
import TaskItem from 'Stores/Task'

type Props = {
  plannerFlow: PlannerFlow;
  task: TaskItem;
  contextMenuPos?: { top: number, left: number };
  makeStickyLabel?: string;
  makeUnStickyLabel?: string;
  toEventLabel?: string;
  removeTaskLabel?: string;
  deleteTaskLabel?: string;
  onHideContextMenu?: () => void;
}

export default class PlannerTaskContextMenu extends React.Component<Props> {

  static defaultProps = {
    makeStickyLabel: 'Make Task Sticky',
    makeUnStickyLabel: 'Make Task Un-Sticky',
    toEventLabel: 'Convert Task To Event',
    removeTaskLabel: 'Remove Task from ActionPlanner',
    deleteTaskLabel: 'Delete Task'
  }

  onToEvent = () => {
    // @todo implements
  }

  handleClick = () => {
    this.props.onHideContextMenu && this.props.onHideContextMenu()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentDidUnMount() {
    document.removeEventListener('click', this.handleClick)
  }

  onDeleteTask = (event) => {
    const { task } = this.props
    event.preventDefault()
    task.trash()
    task.save()
  }

  onRemoveTask = (event) => {
    const { task } = this.props
    event.preventDefault()
    task.onPlanner = false
    task.save()
  }

  onStickyToggle = (event) => {
    const { task } = this.props
    event.preventDefault()
    const taskPiece = this.props.plannerFlow.getFirstTaskPieceForTask(task)
    const { plannerFlow } = taskPiece // @fix props.plannerFlow is not the same as taskPice.plannerFlow
    if (taskPiece) {
      const startDate = plannerFlow.getTaskPieceStartDate(taskPiece)
      console.log('taskPiece', { plannerFlow, taskPiece, startDate })
      task.setSticky(startDate)
      task.setProps({ startDate })
      task.save()
    }

  }

  onConvertToEvent = event => {
    event.preventDefault()
    alert('not implemented')
  }

  render() {
    const { 
      task, contextMenuPos, 
      makeStickyLabel, makeUnStickyLabel, toEventLabel, removeTaskLabel, deleteTaskLabel 
    } = this.props
    const { top } = contextMenuPos
    return (
      <div 
        style={{ top }}
        className="task-context-menu dropdown-menu show"
      >
        <a
          href="#/to-sticky"
          className="dropdown-item"
          onClick={this.onStickyToggle}
        >
          <Icon set={'fa'} icon={'thumbtack'} />
          {task?.isSticky ? makeUnStickyLabel : makeStickyLabel}
        </a>
        <a
          href="#/to-event"
          className="dropdown-item"
          onClick={this.onToEvent}
        >
          <Icon set={'treks'} icon={'convert_to_event'} />
          {toEventLabel}
        </a>
        <a
          href="#/to-event"
          className="dropdown-item"
          onClick={this.onConvertToEvent}
        >
          <Icon icon={'trash'} />
          {deleteTaskLabel}
        </a>
        <a
          href="#/remove"
          className="dropdown-item"
          onClick={this.onRemoveTask}
        >
          <Icon set={'fa'} icon={'times-circle'} />
          {removeTaskLabel}
        </a>
      </div>
    )
  }
}