import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { ProjectItem } from 'Stores/Project'
import { ParentItemTitleInput } from '../Shared/ItemTitleInput'
import { CollapsableIcon } from '../Shared/CollapsableIcon'
import { makeObservable, observable } from 'mobx'
import ListViewProjectStats from './ListViewProjectStats'
import { RemoveBtn } from 'theme/RemoveBtn/RemoveBtn'

class ListViewProjectDetail extends React.Component {

  static propTypes = {
    item: PropTypes.instanceOf(ProjectItem).isRequired
  }

  @observable isCollapsed = false
  onPressCollapsable = () => {
    this.isCollapsed = !this.isCollapsed
  }

  constructor() {
    super()
    makeObservable(this)
  }

  render() {
    const { item } = this.props
    const collapseClass = this.isCollapsed ? ' collapsed' : ''
    const inputStyle = {
      background: item.color
    }
    return (
      <div className={"list-view-category-list"}>
        <header className="category-list-header">
          <ParentItemTitleInput item={item} style={inputStyle} />
          <CollapsableIcon
            onPress={this.onPressCollapsable}
            isCollapsed={this.isCollapsed}
          />
          <RemoveBtn />
        </header>
        <div className={'category-list-body' + collapseClass}>
          <ListViewProjectStats project={item} />
        </div>
      </div>
    )
  }

}

export default observer(ListViewProjectDetail)