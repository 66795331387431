import React from 'react'
import { observer } from 'mobx-react'
import Sortable from '../../Modules/ReactSortable'
import ActionPlannerResizableTask from './Task/ActionPlannerResizableTask'
import { models } from 'Stores'
import { action } from 'mobx'
import { toStartOfDayDate } from 'Stores/ActionPlanner/ActionPlannerUtils'
import { TimespanPieceFlow } from 'Stores/ActionPlanner/PlannerFlow/TimespanPieceFlow'
import { getKeyPressState } from 'uiState/KeypressState'

const debug = require('debug')('treks:planner:timespan:tasks')

let draggingTask = null

class ActionPlannerTimespanTasks extends React.Component {

  altKeys = ['Alt', 'Control', 'Meta', 'Shift']
  isAltPressed = false

  static defaultProps = {
    keyPressState: getKeyPressState()
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  }

  componentDidUnMount() {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  }

  onKeyDown = event =>  {
    if (this.altKeys.includes(event.key)) this.isAltPressed = true
    debug('event', { event, key: event.key })
  }

  onKeyUp = () => {
    if (this.isAltPressed) this.isAltPressed = false
  }

  onDragStart = (event) => {
    const { item, from, to } = event
    debug('onDragStart', { item, from, to })
    item.setAttribute('data-drag', 'drag')
    const taskUid = item.getAttribute('data-id')
    const task = models.TaskItem.findByUid(taskUid)
    draggingTask = task
    this.props.actionPlannerStore.setFocusedTask(task)
  }

  onDragStop = (event) => {
    const { plannerFlow } = this.props
    const { item, from, to, newIndex } = event
    const { isAltPressed } = this
    // dragged out of planner
    if (!to.closest('.action-planner-items')) {
      return
    }

    const taskUid = item.getAttribute('data-id')
    const timespanToUid = to.closest('.action-planner-items').getAttribute('data-id')
    const timespanFromUid = from.closest('.action-planner-items').getAttribute('data-id')
    const task = models.TaskItem.findByUid(taskUid)
    const timespanTo = TimespanPieceFlow.findByUid(timespanToUid)
    const timespanFrom = TimespanPieceFlow.findByUid(timespanFromUid)
    if (isAltPressed) task.earmarkedDate = timespanTo.dayDate
    item.setAttribute('data-drag', '')

    // earmarked if dragged to new day
    const dayFrom = toStartOfDayDate(timespanFrom.startDate)
    const dayTo = toStartOfDayDate(timespanTo.startDate)
    if (dayFrom.getTime() !== dayTo.getTime()) {
      task.earmarkedDate = dayTo
    }

    const plannerOrderSnap = plannerFlow.orderSnapshot
    let timespanFromOrderSnap = timespanFrom.orderSnapshot
    let timespanToOrderSnap = timespanTo.orderSnapshot

    const timespanToStartIndex = plannerOrderSnap.indexOf(timespanToOrderSnap[0])

    const nextTimespanToOrder = [ ...timespanToOrderSnap ]
    const taskIndex = nextTimespanToOrder.indexOf(taskUid)
    if (taskIndex > -1) nextTimespanToOrder.splice(taskIndex, 1) // newIndex assumes task was removed if same timespan
    nextTimespanToOrder.splice(newIndex, 0, taskUid) // add the task to index

    let nextPlannerOrder = [ ...plannerOrderSnap ].map(uid => uid === taskUid ? '' : uid)
    nextPlannerOrder.splice(timespanToStartIndex, timespanToOrderSnap.length, ...nextTimespanToOrder)

    nextPlannerOrder = nextPlannerOrder.filter(uid => uid) // remove moved task

    debug('onDragStop', event, { 
      timespanFrom, timespanTo, task, isAltPressed, newIndex,
      plannerOrderSnap, timespanFromOrderSnap, timespanToOrderSnap, nextTimespanToOrder, nextPlannerOrder
    })
    task.scheduleSave()
    draggingTask = null
    plannerFlow.setOrder(nextPlannerOrder)
    plannerFlow.saveOrder()
  }

  @action onOrderChange = (nextTimespanOrder) => {
    const { timespanPiece, plannerFlow } = this.props
    debug('onOrderChange', { nextTimespanOrder, timespanPiece, draggingTask })

    // drop from other list
    if (!draggingTask) {
      const nextPlannerOrder = [ ...plannerFlow.orderSnapshot ]
      const nextTimespanOrderLen = nextTimespanOrder.length
      const firstTaskUid = nextTimespanOrder[0]
      const firstTaskIndex = nextPlannerOrder.findIndex(uid => uid === firstTaskUid)
      nextPlannerOrder.splice(firstTaskIndex, nextTimespanOrderLen - 1, ...nextTimespanOrder)
      plannerFlow.setOrder(nextPlannerOrder)
      plannerFlow.saveOrder()
      debug('saved nextPlannerOrder', { nextPlannerOrder, firstTaskUid, firstTaskIndex })
      return
    }

    draggingTask.setReOrderDate(new Date()) // for PFA
    const plannerOrderSnap = plannerFlow.orderSnapshot
    const timespanOrderSnap = timespanPiece.orderSnapshot
    const taskUid = draggingTask.uid
    let nextOrder = [ ...plannerOrderSnap ]
    nextOrder.splice(nextOrder.indexOf(taskUid), 1, '') // remove moved task in place
    const timespanStartIndex = plannerOrderSnap.indexOf(timespanOrderSnap[0])
    nextOrder.splice(timespanStartIndex, timespanOrderSnap.length, ...nextTimespanOrder)
    nextOrder = nextOrder.filter(uid => uid) // remove moved task
    debug('nextOrder', { taskUid, timespanStartIndex, plannerOrderSnap, nextOrder })
    // no-op. Prefer onDragStop which has newIndex, oldIndex
  }

  onRemove = event => {
    const { timespan } = this.props
    debug('onRemove', { event, timespan })
    if (!event.item) return
    const uid = event.item.getAttribute('data-id')
    const task = models.TaskItem.findByUid(uid)
    if (task.timespanType === timespan.type) {
      // no-op due to bug
      // remove fires after movinng task between timespans
      debug('remove', { timespan, task })
    }
  }

  onAdd = event => {
    const { timespan, plannerFlow } = this.props
    debug('onAdd', { event, timespan })
    if (!event.item) return
    const uid = event.item.getAttribute('data-id')
    const task = models.TaskItem.findByUid(uid)
    task.setProps({ timespanType: timespan.type, onPlanner: true })
    if (!plannerFlow.taskList.getItemByUid(uid)) {
      plannerFlow.taskList.addItem(task)
    }
    task.scheduleSave()
    debug('dropped task to planner', task)
  }

  onPut = () => {
    const { isAnyCtrlKeyPressed } = this.props.keyPressState
    console.log('onPut timespan tasks', { isAnyCtrlKeyPressed })
    return !isAnyCtrlKeyPressed
  }

  renderTaskPieces() {
    const { plannerUtils, timespanPiece, actionPlannerStore, plannerFlow, plannerOpacityPercent } = this.props
    const { 
      onTaskDurationWillChange, onTaskDurationChange, onTaskDurationUpdate,
      onTaskPress, onContextMenu
    } = this.props
    const { taskPieces }  = timespanPiece
    return (
      <>
        {
          taskPieces.map(taskPiece => (
            <ActionPlannerResizableTask
              key={taskPiece.task.uid}
              plannerOpacityPercent={plannerOpacityPercent}
              plannerFlow={plannerFlow}
              plannerUtils={plannerUtils}
              actionPlannerStore={actionPlannerStore}
              taskPiece={taskPiece}
              item={taskPiece.task}
              onTaskPress={onTaskPress}
              onContextMenu={onContextMenu}
              onTaskDurationWillChange={onTaskDurationWillChange}
              onTaskDurationChange={onTaskDurationChange}
              onTaskDurationUpdate={onTaskDurationUpdate}
            />
          ))
        }
      </>
    )
  }

  render() {
    return (
      <Sortable
        className="timespan-sortable"
        options={{
          get scroll() {
            return document.querySelector('.main-pane-component:has(.action-planner)')
          },
          scrollSensitivity: 80,
          scrollSpeed: 20,
          handle: '.icon-drag',
          animation: 100,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          group: {
            name: 'timespan',
            put: this.onPut
          },
          onStart: this.onDragStart,
          onEnd: this.onDragStop,
          onRemove: this.onRemove,
          onAdd: this.onAdd
        }}
        onChange={this.onOrderChange}
      >
        {
          this.renderTaskPieces()
        }
      </Sortable>
    )
  }
}

export default observer(ActionPlannerTimespanTasks)
