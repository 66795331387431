import { dateAddDays, dateAddHours, toStartOfDayDate } from "Stores/ActionPlanner/ActionPlannerUtils"
import { DayListFlow } from "Stores/ActionPlanner/PlannerFlow/DayListFlow"
import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow"
import { CalendarEvent } from "Stores/Calendar"
import TaskItem from "Stores/Task"

type Mocks = {
  plannerFlow: PlannerFlow
  dayList: DayListFlow
  tasks: TaskItem[]
  earmarkedTasks: TaskItem[]
  startDate: Date
  endDate: Date
  focusedDay: Date
  events: CalendarEvent[]
}

export const createMockTasks = (len: number, propsFn?: (i: number) => any): TaskItem[] => {
  return new Array(len).fill({}).map((task, i) => ({
    ...task,
    uid: 'task-' + i,
    title: 'Task ' + i,
    duration: 60,
    onPlanner: true,
    timespanType: 'work',
    ...(propsFn ? propsFn(i) : {})
  }))
}

export const createMockEvents = (len: number, propsFn?: (i: number) => any): CalendarEvent[] => {
  const startOfDay = toStartOfDayDate(new Date())
  return new Array(len).fill({}).map((event, i) => {
    const startDate = dateAddHours(startOfDay, 4 * i)
    return ({
      ...event,
      uid: 'event-' + i,
      id: 'event-' + i,
      eventId: 'event-' + i,
      title: 'Event ' + i,
      onPlanner: true,
      startDate,
      endDate: dateAddHours(startDate, 2),
      ...(propsFn ? propsFn(i) : {})
    })
  })
}

export const mocks: Mocks = {
  plannerFlow: null,
  dayList: null,
  tasks: createMockTasks(20),
  earmarkedTasks: createMockTasks(5, i => ({ uid: 'earmaked-task-' + i, title: 'Earmarked Task ' + i })),
  events: createMockEvents(10),
  focusedDay: new Date(),
  startDate: new Date(),
  endDate: dateAddDays(new Date(), 1)
}