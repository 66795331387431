import React from 'react'
import { observer } from 'mobx-react';
import { getAppNotifications } from '../../../AppNotifications';
import ActionPlannerUtils, { dateAddDays } from 'Stores/ActionPlanner/ActionPlannerUtils';
import { DraggableWithScroll } from 'Modules/Draggable/DraggaleWithScroll';
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow';
import { DatedItemFlow } from 'Stores/ActionPlanner/PlannerFlow/Event/DatedItemFlow';
import CalendarEvent from '../CalendarEvent/CalendarEvent';

const debug = require('debug')('treks:planner:DraggableEvent')

function createColor(text: string): string {
  // Convert the text to a number using a hashing function
  const hash = text.split('').reduce((acc, char) => ((acc << 5) - acc) + char.charCodeAt(0), 0);

  // Use the hash value to generate a consistent hex color
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += ((hash >> (i * 4)) & 0xF).toString(16);
  }

  return color;
}

type WeekDraggableEventProps = {
  plannerFlow: PlannerFlow;
  plannerUtils: ActionPlannerUtils;
  event: DatedItemFlow;
  dragDirection: string;
  stackOffset: number;
  maxStacks: number;
  maxWidth: number;
  widthOffset: number;
  onTaskOffset: number;
  leftOffset: number;
  topOffset: number;
  saveEventErrorMsg: string;
  onShowContextMenu: (props: { nativeEvent: MouseEvent, event: React.MouseEvent<HTMLDivElement> }) => void;
}

class DraggableEvent extends React.Component<WeekDraggableEventProps> {

  elementRef = null
  elementClone = null

  prevTop = 0
  isDragging = false

  static defaultProps = {
    stackOffset: 50,
    maxStacks: 8,
    maxWidth: '100%',
    widthOffset: 120,
    onTaskOffset: 50,
    leftOffset: 0,
    saveEventErrorMsg: 'Failed to save event update'
  }

  getPlannerEl(): HTMLDivElement {
    return document.querySelector('.action-planner-body')
  }

  onElementRef = ref => {
    if (ref) this.elementRef = ref
  }

  onDragStart = () => {
    const { event } = this.props
    document.body.style.cursor = 'grab'
    this.isDragging = true
    event.saveOnUpdate()
      .catch(() => getAppNotifications().error(this.props.saveEventErrorMsg))
  }

  onDrag = ({ top, left }) => {
    debug('onDrag', { top, left })
    this.setEventPosition({ top, left })
    return false
  }

  onDragEnd = ({ top, left }) => {
    const { event } = this.props
    document.body.style.cursor = ''
    this.setEventPosition({ top, left })
    event.leftDragOffset = 0
    this.isDragging = false

    // set day if changed
    const plannerEl = this.getPlannerEl()
    const dayColWidth = plannerEl.offsetWidth / 7
    if (Math.abs(left) > dayColWidth/2) {
      const dayDelta = Math.round(left/dayColWidth)
      event.startDate = dateAddDays(event.startDate, dayDelta)
      event.endDate = dateAddDays(event.endDate, dayDelta)
    }
  }

  setEventPosition({ top, left }) {
    debug('setEventPosition', { top, left })
   this.props.event.leftDragOffset = left
    this.setEventMinsSinceStartOfDay(top)
  }

  setEventMinsSinceStartOfDay(top) {
    const { event, plannerUtils } = this.props
    const offsetMins = plannerUtils.getDurationFromHeight(top)
    event.setMinutesSinceStartOfDay(offsetMins)
    this.prevTop = top
    this.scrollActionPlannerIfEventHidden(top)
    debug({ offsetMins, top })
  }

  scrollActionPlannerIfEventHidden(top) {
    const pane = document.querySelector('.main-pane-component:has(.action-planner-body)')
    if (pane.scrollTop > top) {
      pane.scrollTop = top
    }
  }
  
  onShowContextMenu(params) {
    const { onShowContextMenu } = this.props
    onShowContextMenu && onShowContextMenu(params)
  }

  render() {
    const { plannerFlow, plannerUtils, event, dragDirection, stackOffset, maxStacks, maxWidth, widthOffset, onTaskOffset, leftOffset, topOffset } = this.props
    
    const onTasks = plannerFlow.getTaskPiecesOnDatedItem(event)
    // @important week view top/start is always start of day 
    const startDuration = plannerFlow.getMinsSinceStartOfDay(event.startDate)
    const height = plannerUtils.getHeightFromDuration(event.duration)
    const top = plannerUtils.getHeightFromDuration(startDuration)

    const onTask = onTasks && onTasks[0]
    const titleOffset = 7
    const maxStackOffset = stackOffset * maxStacks
    const stackIndex = event.stackIndex
    const isOverTaskTitle = onTask && (onTask.startDuration + titleOffset >= event.minutesSinceStartOfDay)
    const onHoveredTaskWidth = onTask && onTask.isHovered && isOverTaskTitle && !this.isDragging ? 200 : 0
    const stackWidth = stackIndex * stackOffset
    const left = Math.min(Math.max(stackWidth, onHoveredTaskWidth), maxStackOffset)
    const transform = `translate(0px, ${top}px)`
    const width = `calc(${maxWidth} - ${widthOffset + (onTask ? onTaskOffset: 0)}px)`
    const zIndex = 10 + stackIndex
    const onTaskClass = onTask ? ' onTask' : ''
    const onActiveTaskClass = onTask && onTask.isFocused ? ' onFocusedTask' : ''
    const onDoneTaskClass = onTask && onTask.done ? ' onDoneTask' : ''
    const onHoveredTaskClass = onTask && onTask.isHovered && isOverTaskTitle ? ' onHoveredTask' : ''
    const draggingClass = this.isDragging ? ' dragging' : ''
    const stackIndexClass = ' stack-' + stackIndex
    const onPlannerClass = event.onPlanner ? ' onPlanner' : ''
    const className = 'draggable-event' + onPlannerClass + draggingClass + onTaskClass 
      + onActiveTaskClass + onDoneTaskClass + onHoveredTaskClass + stackIndexClass
    const leftCalc = `calc(${typeof leftOffset === 'number' ? leftOffset + 'px' : leftOffset}`
      + ` + ${event.leftDragOffset}px)`
    const topCalc = topOffset
    const innerHeight = height - 10

    const overlappingEvents = plannerFlow.getEventsOverlapping(event)
    const overlapEndDuration = plannerFlow.getEventsOverlappingEndDuration(event)
    const overlapPad = overlappingEvents.length > 1 ? 5 : 0
    const cutoutHeight = plannerUtils.getHeightFromDuration(overlapEndDuration - event.startDuration) - overlapPad

    const color = event.datedItem.calendar?.backgroundColor || createColor(event.datedItem.title)

    console.log('render', { overlappingEvents, event, stackIndex, stackOffset, stackWidth, onTask, transform, topCalc, startDuration })
    return (
      <DraggableWithScroll
        ref={this.onElementRef}
        className={className}
        dragDirection={dragDirection}
        handleCssSelector={'.icon-drag'}
        onDrag={this.onDrag}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        style={{ transform, width, height, zIndex, left: leftCalc, top: topCalc  }}
        onContextMenu={({ nativeEvent }) => this.onShowContextMenu({ nativeEvent, event })}
      >
        <div
          className='draggable-event-cutout'
          style={{ height: cutoutHeight }}
        >
          <CalendarEvent
            plannerUtils={plannerUtils}
            event={event}
            style={{
              height: innerHeight,
              marginLeft: left,
              zIndex: 50,
              backgroundColor: `${color}${event.onPlanner ? '2A': '7F'}`
            }}
          />
        </div>
      </DraggableWithScroll>
    )
  }
}

export default observer(DraggableEvent)