import React from 'react'
import { observer } from 'mobx-react'
import Dropdown from 'react-bootstrap/Dropdown'
import { CheckIcon } from 'lucide-react'

const debug = require('debug')('treks:project:tasks:sort')

class ProjectTasksSort extends React.Component {

  static defaultProps = {
    order: '',
    items: [
      {
        label: 'Order',
        value: ''
      },
      {
        label: 'Asc',
        value: 'asc'
      },
      {
        label: 'Desc',
        value: 'desc'
      }
    ]
  }

  setOrder = order => {
    debug("order is", order)
    this.props.onSetOrder(order)
  }

  render() {
    const { items, order } = this.props
    const selected = items.find(item => item.value === order)
    debug('render', { order, selected })
    return (
      <div className="tab-sort">
        <span>Sort</span>
        <Dropdown>
          <Dropdown.Toggle size="sm" variant="outline-dark" id="menu-sub-tasks">
            { selected?.label || 'Order'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              items.map(item => {
                return (
                  <Dropdown.Item style={{ display: 'flex'}} onClick={() => this.setOrder(item.value)}>
                    {item.label} 
                    {
                      selected?.value === item.value ? (
                        <CheckIcon size={18} style={{ marginLeft: 'auto' }} />
                      ) : null
                    }
                  </Dropdown.Item>
                )
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}

export default observer(ProjectTasksSort)