import {
  observable,
  action,
  computed,
  reaction,
  toJS,
  override,
  intercept,
} from 'mobx';
import { ApiRequest } from 'Stores/Service'
import moment from 'moment'
import { items as dayItems, DayList, timeSpanBucket, emptyTimeSpan, TimeSpanList, TimeSpanItem } from 'Stores/ActionPlanner/TimeSpan'
import ActionPlannerEventList from './ActionPlannerEventList';
import ActionPlannerUtils, { isSameDay, toDayId, toDayIndex, toStartOfDayDate } from 'Stores/ActionPlanner/ActionPlannerUtils';
import { ItemPropsI } from 'Stores/Lists/Type/Item';
import { DateModel } from 'Stores/Model/DateModel';
import { getStore, models } from 'Stores/Stores';
import { TimeSpanListDaySettings } from './TimeSpan/Settings';
import { Account } from 'Stores/Account/Account';
import TaskItem, { TaskList } from 'Stores/Task';
import { CalendarEvent } from 'Stores/Calendar';
import { JsonAny } from 'Stores/Model/Type/Json';
import { getActionPlannerAppStore } from './ActionPlannerApp';
import { DatedItemFlow } from './PlannerFlow/Event/DatedItemFlow';

const debug = require('debug')('treks:store:planner')

export type ActionPlannerOpts = {
  dateFormat: string,
  timeFormat: string,
  durationStep: number,
  durationInterval: number,
  durationHeightPerInterval: number,
  taskMargin: number,
  minDuration: number,
  minutesPerRow?: number,
}

// global to all planners
const items = observable.array([])
const order = observable.array([])

// @fix ensure unique as dupes may creep in
intercept(items, (change) => {
  change.added = removeDuplicateItems(change.added)
  return change
})

intercept(order, (change) => {
  debug('order change', change.added.map(uid => {
    // @important use findModel not getModel
    // @important getModel has side-effects
    return models.TaskItem.findModel({ uid })?.title
  }))
  return change
})

function removeDuplicateItems(items) {
  const uids = items.map(item => item.uid)
    const uuids = uids
      .filter(uid => uid) // non-empty
      .filter((uid, index) => {
        return uids.indexOf(uid) === index // non-dupe
      })
    return uuids.map(uid => items.find(item => item.uid === uid))
}

/**
 * ActionPlanner Store
 */
export default class ActionPlanner extends TaskList {

  // global planner app
  plannerApp = getActionPlannerAppStore()

  @override get items(): TaskItem[] {
    // all modifications CANNOT replace {items} ref. eg: setItems() -> items.splice(...)
    return items // observable.array ref. 
  }

  @override get order(): string[] {
    return order
  }

  /**
   * Specific to planner UI (not tasks)
   * @property {object}
   */
  @observable opts: ActionPlannerOpts = {
    dateFormat: 'MMMM D, YYYY',
    timeFormat: 'h:mm A',
    durationStep: 1,
    durationInterval: 15, 
    durationHeightPerInterval: 60, 
    taskMargin: 0,
    minDuration: 5,
  }

  setOpts(opts: ActionPlannerOpts) {
    debug('set planner opts', opts, this.opts)
    Object.assign(this.opts, opts)
  }

  @observable isPlanner = true

  @computed get utils(): ActionPlannerUtils {
    debug('planner utils update', this.opts)
    return new ActionPlannerUtils(this.opts)
  }

  // @note show all events loaded 
  // multiday planner is too complex to load just visible day events
  @computed get events(): CalendarEvent[] {
    return this.eventList.visibleEvents
  }

  @computed get visibleEvents(): CalendarEvent[] {
    return this.events.filter(event => event.deleted !== true && event.onPlanner !== false)
  }

  /**
   * @property {EventList}
   */
  @observable eventList: ActionPlannerEventList = ActionPlannerEventList.create()

  @action setEvents(events: CalendarEvent[]) {
    this.eventList.setItems(events)
  }

  /**
   * @note server optimized by pushing only todays events
   */
  @computed get focusedDateEvents(): CalendarEvent[] {
    const { startDate, endDate } = this
    return this.eventList.visibleEvents
      .filter(event => event.startDate >= startDate && event.endDate < endDate)
  }

  @computed get account(): Account {
    return getStore(models.Session).Account
  }

  /**
   * @property {Date} Date accurate to the second
   */
  @override get currentDate(): Date {
    return this.getAttribute('currentDate') || DateModel.currentDate
  }
  // for testing only
  mockCurrentDate(date: Date) {
    this.setAttribute('currentDate', date)
  }

  /**
   * @property {Date} Start of Today (Different from focused day)
   */
  @computed get startOfTodayDate(): Date {
    return new Date(new Date(this.currentDate).setHours(0, 0, 0, 0))
  }

  @computed get minsSinceStartOfDay(): number {
    return moment(this.currentDate).diff(this.startOfTodayDate, 'minutes')
  }

  @computed get isFocusedOnToday(): boolean {
    return isSameDay(this.currentDate, this.focusedDate)
  }

  set focusedDate(focusedDate: Date) {
    this.setAttribute('focusedDate', new Date(new Date(focusedDate).setHours(0, 0, 0, 0)))
  }

  @computed get focusedDate(): Date {
    return this.getAttribute('focusedDate', () => new Date(new Date().setHours(0, 0, 0, 0)))
  }

  @computed get focusedDay(): TimeSpanList {
    return this.dayList.items.find(day => {
        return day.dayDate.getTime() === this.focusedDate.getTime()
      })
  }

  @computed get todayTimespanList(): TimeSpanList {
    return this.dayList?.items.find(day => {
      return day.dayDate.getTime() === this.startOfTodayDate.getTime()
    })
  } 

  @action setFocusedDate(date: Date) {
    let nextDate = new Date(date)
    if (isNaN(nextDate.getTime())) {
      throw new TypeError('setFocusedDate must receive a Date as first parameter')
    }
    nextDate = toStartOfDayDate(nextDate)
    // prevent updates because date ref changed
    if (nextDate.getTime() !== this.focusedDate.getTime()) {
      this.focusedDate = nextDate
    }
  }

  @action focusNextDay(days: number = 1) {
    this.focusedDate = new Date(new Date(this.focusedDate).setHours(days * 24))
  }

  /**
   * @property {Date} Date of planner focused day start
   */
  @computed get startDate(): Date {
    return new Date(this.focusedDate)
  }

  /**
   * @property {Date} Date of planner focused day end
   */
  @computed get endDate(): Date {
    return new Date(new Date(this.focusedDate).setHours(24))
  }

  @observable startOfWeekIndex: number = 0

  @computed get startOfWeekDate(): Date {
    const focusedDate = new Date(this.focusedDate) // copy
    const dayOfMonth = focusedDate.getDate()
    const dayOfWeek = focusedDate.getDay()
    const startDay =  new Date(focusedDate.setDate(dayOfMonth - dayOfWeek + this.startOfWeekIndex))
    const zeroHour = new Date(startDay.setHours(0, 0, 0))
    return zeroHour
  }

  @computed get endOfWeekDate(): Date {
    const startOfWeek = new Date(this.startOfWeekDate) // copy
    return new Date(startOfWeek.setDate(startOfWeek.getDate() + 7))
  }

  @computed get weekStartDay(): TimeSpanList {
    return this.dayList.startDay
  }

  @computed get weekEndDay(): TimeSpanList {
    return this.dayList.endDay
  }

  /**
   * @property {DayList} List of TimespanList (Days) in planner. 
   * @note Can be a week or multi-day. Loading and viewing is up to component render()
   */
  @observable dayList: DayList = DayList.fromProps({ 
    actionPlanner: this, 
    items: dayItems, 
    bucket: timeSpanBucket, 
    emptyItem: emptyTimeSpan 
  })

  @computed get focusedDayIndex() {
    return toDayIndex(this.focusedDate)
  }

  @computed get focusedDayId(): string {
    return toDayId(this.focusedDate)
  }

   @computed get dayTimespans(): TimeSpanListDaySettings  {
    return this.dayList?.getItemByDayId(this.focusedDayId) as TimeSpanListDaySettings
  }

  @computed get itemsDue(): (CalendarEvent|TaskItem)[] {
    const { dueTasks, dueEvents } = this
    return [...dueTasks, ...dueEvents]
  }

  @computed get dueTasks(): TaskItem[] {
    return this.items.filter(({ dueDate, onPlanner, done }) => {
      return dueDate && isSameDay(dueDate, this.startOfTodayDate) && !onPlanner && !done
    })
  }

  @computed get doneTasks(): TaskItem[] {
    return this.items.filter(({ done }) => {
      return done
    })
  }

  @computed get dueEvents(): CalendarEvent[] {
    return this.eventList.items.filter(event => {
      return event.allDayEvent && isSameDay(event.startDate, this.startOfTodayDate)
    })
  }

  @override get visibleItems(): TaskItem[] {
    return this.itemsOrdered
      .filter(item => item.onPlanner
        && !item.isSticky
        && !item.trashed
      )
  }

  @computed get stickyTasks(): TaskItem[] {
    return this.itemsOrdered.filter(task => task.isSticky)
  }

  @computed get allVisibleTasks(): TaskItem[] {
    return [ ...this.stickyTasks, ...this.visibleItems ]
  }

  @computed get sortedStickyTasks(): TaskItem[] {
    return this.stickyTasks
      .sort((task1, task2) => {
        return task1.startDate > task2.startDate
          ? 1 : (task1.startDate < task2.startDate ? -1 : 0)
      })
  }

  @computed get totalDuration(): number {
    return this.items.reduce((sum, { duration }) => sum + duration, 0)
  }

  @computed get timespanUniqueTypesOrdered(): string[] {
    const allTypes =  this.items
      .map(item => item.timespanType)
    return allTypes.filter((type, index) => allTypes.indexOf(type) === index)
  }

  @computed get itemsByOrder(): TaskItem[] {
    return this.orderItemsByOrder(this.items, this.getOrder())
  }

  @computed get visibleItemsByOrder(): TaskItem[] {
    return this.itemsByOrder
      .filter(item => item.onPlanner
        && !item.isSticky
        && !item.trashed
      )
  }

  /**
   * @property {Array<TaskItem>} Items ordered 
   * > grouped by same timespan type
   * > sorted by sortedItems index
   * > sorted by done items first
   * > sorted by done date
   * @note order is not same as appearing in action planner 
   * @note since two timespans of same type are grouped
   */
  @computed get itemsOrdered(): TaskItem[] {
    const orderedItems = this.itemsByOrder
    // group by timespan type
    const groupsByTimespanType = this.timespanUniqueTypesOrdered.map(timespanType => {
      return orderedItems.filter(item => item.timespanType === timespanType)
    })

    const doneOrdedGroups = groupsByTimespanType.map(group => {
      // done items ordered by doneDate
      const doneItems = group
        .filter(({ done }) => done)
        .sort((a, b) => {
          if (a.doneDate < b.doneDate) return -1
          if (a.doneDate > b.doneDate) return 1
          return 0
        })
      const notDoneItems = group.filter(({ done }) => !done)
      const doneOrderedItems = [ ...doneItems,  ...notDoneItems ]
      return doneOrderedItems
    })
    
    const doneOrderedItems = doneOrdedGroups.flat()
    
    debug('re-ordered items', { orderedItems, doneOrderedItems })
    return doneOrderedItems
  }

  getOrder(): string[] {
    const uids = this.items.map(item => item.uid)
    const orderedUids = [ ...this.order, ...uids ]
    const order = orderedUids
      .filter((uid, index) => orderedUids.indexOf(uid) === index)
    return order
  }

  /**
   * @param {array} Ordered Array of item ids
   * @important Do not overwrite this.order reference
   */
  @override setOrder(order: string[] = []) {
    if (!Array.isArray(order)) {
      throw new Error('Order must be an Array<string>')
    }
    const uniqueOrder = order
      .filter(uid => uid) // only non-empty
      .filter((uid, index) => order.indexOf(uid) === index) // only unique
    this.order.splice(0, this.order.length, ...uniqueOrder)
  }

  /**
   * @todo move utility function
   */
  orderItemsByOrder(items: TaskItem[], order: string[]): TaskItem[] {
    const sortedItems = this.getItemsByUids(order)
      .filter(item => item) // order may be stale, remove non-existent items
    const missingItems = items.filter(item => !sortedItems.includes(item))
    // we sort without dropping any items not in sport array
    const orderedItems = [ ...sortedItems, ...missingItems ]
    return orderedItems
  }

  @action setItemsOrderForTimespan(order: string[] = [], timespan: TimeSpanItem) {
    const planerOrder = this.getOrder()
    const timespanOrder = timespan.getOrder()
    const orderStart = planerOrder.indexOf(timespanOrder[0])
    planerOrder.splice(orderStart, timespanOrder.length, ...order)
    const diff = this.getOrder().filter((uid, index) => planerOrder.indexOf(uid) !== index)
    debug("set timespan order", { planerOrder, order, timespanOrder, diff })
    this.setOrder(planerOrder)
  }

  @computed get timeFocusedTimespan(): TimeSpanItem {
    const { minsSinceStartOfDay } = this
    return this.dayTimespans.items.find(timespan => {
      return timespan.startDuration <= minsSinceStartOfDay && timespan.endDuration > minsSinceStartOfDay
    })
  }

  @computed get focusedTimespan(): TimeSpanItem {
    return this.getAttribute('focusedTimespan')
  }

  @action setFocusedTimespan(timespan: TimeSpanItem) {
    this.setAttribute('focusedTimespan', timespan)
  }

  @computed get focusedTask() {
    return this.focusedItem.item
  }

  @action setFocusedTask(task: TaskItem) {
    this.setFocusedItem(task)
  }

  @observable focusedEvent: CalendarEvent = null
  
  @observable orderState: ApiRequest = new ApiRequest()

  constructor(items?: TaskItem[], order?: string[], opts?: any) {
    super(items)
    order && this.setOrder(order)
    opts && this.setOpts(opts)
  }

  getSubtaskByIdString(id: string) {
    const task = this.items.find(task => task.getSubTaskByIdString(id))
    return task && task.getSubTaskByIdString(id)
  }

  getItemsByIds(ids: string[]) {
    return ids.map(id => this.getItemByIdString(id) || this.getSubtaskByIdString(id))
  }

  getSubtaskByUid(uid: string) {
    return this.items.reduce((found, task) => {
      return task.getSubTaskByUid(uid) || found
    }, null)
  }

  getItemsByUids(uids: string[]): TaskItem[] {
    return uids.map(uid => this.getItemByUid(uid) as TaskItem || this.getSubtaskByUid(uid))
  }

  /**
   * Set TaskItem|CalendarEvent as focused
   */
  @action setFocusedDatedItem(item: DatedItemFlow) {
    if (item.datedItem instanceof CalendarEvent) {
      this.setFocusedEvent(item.datedItem as CalendarEvent)
    } else {
      this.setFocusedTask(item.datedItem as TaskItem)
    }
    
  }

  @action setFocusedEvent(item: CalendarEvent) {
    this.focusedEvent = item
  }

  /**
   * @note Batched. Can only be editing one task at a time
   */
  @action setEditingItem(task?: TaskItem) {
    this.items.forEach(item => {
      item.editing = item.id === task?.id ? true : false
    })
  }

  syncTaskEvents() {
    reaction(
      () => toJS([
        this.visibleItems.map(task => task.duration), 
        this.events.map(event => [event.startDate, event.endDate]), 
        this.dayTimespans.items.map(timespan => timespan.duration)
      ]), 
      () => {
        return this.dayTimespans.items.map(timespan => timespan.syncTaskEvents())
      })
  }


  getEventsStartingWithinOffsetTop(start, end) {
    const startDate = this.getDateFromDuration(start)
    const endDate = this.getDateFromDuration(end)
    return this.visibleEvents.filter(event => {
      return event.startDate >= startDate && event.startDate < endDate
    })
  }

  /**
   * Retrieve events lying within task
   * @param {TaskItem} task 
   */
  getTaskEvents(task) {
    return task.events
  }

  /**
   * Retrieve task event is over
   * @param {CalendarEvent} event 
   */
  getEventTask(event) {
    return this.visibleItems.find(task => task.events.includes(event))
  }

  /**
   * Retrieve visible task at a given Date
   * @param {Date}
   */
  getTaskAtDate(date) {
    const timespanAtDate = this.getTimespanAtDate(date)
    return timespanAtDate && timespanAtDate.getTaskAtDate(date)
  }

  getTimespanAtDate(date) {
    return this.dayTimespans.items
        .find(timespan => timespan.startDate <= date && timespan.endDate > date)
  }

  /**
   * Timespans the event spans
   */
  getEventTimespans(event) {
    return this.dayTimespans.items
        .filter(timespan => timespan.startDuration <= event.startDuration && timespan.endDuration > event.endDuration)
  }

  getTaskIndex(task) {
    return this.orderedItems.indexOf(task)
  }

  getPrevTasks(task) {
    const index = this.getTaskIndex(task)
    return this.visibleItems.slice(0, index - 1)
  }

  getPrevTask(task) {
    const index = this.getTaskIndex(task)
    return this.visibleItems[index - 1]
  }

  getNextTask(task) {
    const index = this.getTaskIndex(task)
    return this.visibleItems[index + 1]
  }

  @action fetchLocal() {
    return this.localState.get('tasks')
      .then(data => this.fromJSON(data))
  }

  saveLocal() {
    this.localState.set('tasks', this.toJSON())
  }

  findItemByTaskId(taskId) {
    return this.items.find(item => item.taskId.toString() === taskId.toString())
  }

  saveOrder() {
    const order = [ ...this.order ]
    debug('saving order', order)
    return this.orderState.postJson('planner/order/save', { order })
  }

  @action fetchOrder() {
    debug('fetch order')
    return this.orderState.get('planner/order')
      .then(resp => {
        debug('fetched order', resp)
        if (resp?.data?.order) {
          const order = JSON.parse(resp.data.order)
          debug('set order', order)
          if (Array.isArray(order)) {
            this.setOrder(order)
          } else {
            console.warn('Fetched order was not an Array<string>', order)
          }
        }
        return resp
      })
  }

  @action fetchEvents() {
    return this.eventList.fetch(this.focusedDate)
  }

  @action fetchWeekEvents() {
    return this.eventList.fetchWeek(this.startOfWeekDate)
  }

  @action async fetchTimespanTypes() {
    return this.dayList.bucket.fetched()
  }

  @override async fetch() {
    const timestamp = Date.parse(this.focusedDate.toString())
    this.fetchTimespanTypes()
    this.fetchState.get('planner/tasks?date=' + timestamp)
      .then(resp => {
        this.setItemsFromJSON(resp.data)
        return resp
      })
    return this
  }

  @action async fetchWeek(): Promise<JsonAny> {
    const timestamp = Date.parse(this.weekStartDay.dayDate.toString())
    this.fetchTimespanTypes()
    return this.fetchState.get('planner/tasks?date=' + timestamp)
      .then(resp => {
        this.setItemsFromJSON(resp.data)
        return resp
      })
  }

  @override async save() {
    throw new Error('Saving planner list is deprecated')
  }

  getDateFromDuration(duration) {
    return new Date(new Date(this.focusedDate).setMinutes(duration))
  }

  getDateFromOffsetTop(offsetTop) {
    const minsSinceStartOfDay = this.utils.getDurationFromHeight(offsetTop)
    return this.getDateFromDuration(minsSinceStartOfDay)
  }

  /**
   * @param {Date} date 
   * @return {Number} minutes
   */
  getTimeInMinutesFromDate(date) {
    return date && date.getHours && (date.getHours() * 60) + date.getMinutes()
  }

  formatDateTime(date) {
    const time = this.getTimeInMinutesFromDate(date)
    const { dateFormat, timeFormat } = this.opts
    const format = dateFormat + (time ? '     ' +  timeFormat : '')
    return date && moment(date).isValid() ? moment(date).format(format) : ''
  }

  @computed get currentTimeFormatted() {
    return moment(this.currentDate).format('h:mm A')
  }

  defaultTimespanType = 'work'

  getFocusedOrDefaultTimespanType() {
    return this.focusedTimespan?.type 
      || this.focusedItem.item?.timespanType
      || this.defaultTimespanType
  }

   createEmptyItemProps(props?: any): ItemPropsI {
    return super.createEmptyItemProps({
      timespanType: this.getFocusedOrDefaultTimespanType(),
      ...props
    })
  }

  @observable resizingTask: TaskItem = null

}
