import React from 'react'
import { observer } from 'mobx-react'
import ListViewTasksContainer from '../ListViewTasks'
import ProjectList from 'Stores/Project/ProjectList'
import ListViewProjectDetail from './ListViewProjectDetail'
import './ListViewProjects.scss'
import { getStore, models } from 'Stores'
import { ListViewList } from '../Shared/ListViewContainer/models/ListViewList'

class ListViewProjectsContainer extends React.Component {

  static defaultProps = {
    parentLists: ListViewList.create(),
    headers: [ // order matters
      {
        name: 'parent',
        label: 'PROJECT:Category',
        required: true
      },
      {
        name: 'item_name',
        label: 'Project Name',
        required: true
      },
      {
        name: 'due_date',
        label: 'Due Date'
      },
      {
        name: 'overdue_tasks',
        label: 'Overdue Tasks'
      },
      {
        name: 'duration',
        label: 'Duration'
      }
    ]
  }

  async componentDidMount() {
    const { parentLists } = this.props
    const projectList = getStore(ProjectList)
    parentLists.setItems([
      projectList
    ])
    projectList.fetchOrder()
    await projectList.fetched()
    projectList.visibleItems.forEach(project => {
      project.taskList.fetchNextPage()
      project.categoryList.items.forEach(category => {
        category.taskList.fetchNextPage()
      })
    })
  }

  render() {
    return (
      <ListViewTasksContainer
        {...this.props}
        className="list-view-projects"
        allowFlatList={false}
        ListViewTaskCategoryList={ListViewProjectDetail}
      />
    )
  }
  
}

export default observer(ListViewProjectsContainer)