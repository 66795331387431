import React from 'react'
import PropTypes from 'prop-types'
import './WeekPlannerTimes.scss'
import stores from 'Stores'
import { observer } from 'mobx-react'
import ActionPlannerUtils from 'Stores/ActionPlanner/ActionPlannerUtils'

const debug = require('debug')('treks:planner:times')

class WeekPlannerTimes extends React.Component {
  
  static propTypes = {
    dayTimespans: PropTypes.objectOf(() => stores.TimeSpanList).isRequired,
    plannerUtils: PropTypes.objectOf(() => ActionPlannerUtils).isRequired
  }

  static defaultProps = {
    minutesPerRow: 60
  }

  createTimeItem = (time, index, height, timespan) => {
    const hour = parseInt(time/60%12, 10) || 12
    const min = time%60
    const ampm = time < 12*60 ? 'am' : 'pm'
    return { 
      id: index, hour, min, ampm, height, timespan
    }
  }

  getTimeItems() {
    const { plannerUtils, dayTimespans, minutesPerRow } = this.props
    const height = plannerUtils.getHeightFromDuration(minutesPerRow, 0)

    let time = 0;
    let times = [time]

    while (time < 24*60) {
      times.push(time += minutesPerRow)
    }

    const items = times.map((time, index) => {
      const timespan = dayTimespans.getItemByDuration(time)
      return this.createTimeItem(time, index, height, timespan)
    })
    return items
  }

  renderTime = ({ id, hour, min, ampm, height, timespan }) => {
    const { showTimeLabels } = this.props
    const label = hour + ':' + (min  || '00') + ' ' + ampm
    const hourClass = min === 0 ? 'on-hour' : ''
    const halfHourClass = min === 30 ? 'on-half-hour' : ''
    const timespanClass = timespan ? 'timespan-' + timespan.type : 'timespan-empty'
    return (
      <li className={`label-item ${hourClass} ${halfHourClass} ${timespanClass}`} key={id} style={{
        height,
      }}>
        { 
          (showTimeLabels && min%15 === 0) ? <span>{label}</span> : null
        }
      </li>
    )
  }

  gradientCSS() {
    const { plannerUtils, dayTimespans } = this.props
    const gradientStr = dayTimespans.gradientList.map(({ color, prevDuration, currDuration }) => {
      const prevHeight = plannerUtils.getHeightFromDuration(prevDuration)
      const currHeight = plannerUtils.getHeightFromDuration(currDuration)
      return `${color} ${prevHeight}px ${currHeight}px`
    }).join(',')
    return `linear-gradient( ${gradientStr} )`
  }

  render() {
    const items = this.getTimeItems()
    debug('dayTimespans', this.props.dayTimespans)
    return (
      <div className="week-planner-times">
        <ul>
          {
            items.map(this.renderTime)
          }
        </ul>
      </div>
    )
  }
}

export default observer(WeekPlannerTimes)
